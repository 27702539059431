.todo-list {
  padding: 60px;
  padding-top: 10px;
  width: 600px;
}
.todo-settings-toggle {
  fill: #b7b9bd;
  width: 25px;
  margin-left: 10px;
  cursor: pointer;
}
.todo-create-wrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.todo-actions {
  display: flex;
  align-items: center;
}
.todo-create-input {
  font-size: 14px;
  padding: 11px 15px;
  min-width: 300px;
  display: inline-block;
  box-shadow: 0px 0px 0px 2px rgba(120, 130, 152, 0.25);
  border: none;
  outline: none;
  transition: all 0.3s ease;
}
.todo-create-input:hover, .todo-create-input:active, .todo-create-input:focus {
  box-shadow: 0px 0px 0px 2px rgb(43, 190, 185);
  box-shadow: 0px 0px 0px 2px #00ad9f;
}

.todo-item {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 43px;
}
.todo-list-title {
  font-size: 17px;
  font-weight: 500;
  color: #5a5a5a;
  flex-grow: 1;
  position: relative;
  z-index: 2;
  margin-left: 45px;
  width: 470px;
}
.todo-list-title:hover span[contenteditable="false"]:before {
  content: 'click to edit';
  position: absolute;
  top: -6px;
  left: 11px;
  font-size: 11px;
  font-weight: 300;
  color: #adadad;
  letter-spacing: 1px;
}
.mobile-toggle {
  display: none;
}
.desktop-toggle {
  margin-left: 10px;
  margin-bottom: 3px;
}

@media (max-width: 768px) {
  .mobile-toggle {
    display: inline-flex;
  }
  .desktop-toggle {
    display: none;
  }
  .todo-list {
    padding: 15px;
    padding-top: 10px;
    width: auto;
  }
  .todo-list h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    max-width: 95%;
  }
  .todo-list-title {
    /* Disable Auto Zoom in Input “Text” tag - Safari on iPhone */
    font-size: 16px;
    max-width: 80%;
    margin-left: 40px;
  }
  .todo-create-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .todo-create-input {
    appearance: none;
    border: 1px solid rgba(120, 130, 152, 0.25);
    /* Disable Auto Zoom in Input “Text” tag - Safari on iPhone */
    font-size: 16px;
    margin-bottom: 15px;
    min-width: 85%;
  }
  .todo-item button {
    padding: 4px 12px;
    font-size: 14px;
    margin-bottom: 0px;
    min-width: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .todo-list-title:hover span[contenteditable="false"]:before {
    content: ''
  }
  .todo-list-title:hover span[contenteditable="true"]:before {
    content: 'click to edit';
    position: absolute;
    top: -20px;
    left: 9px;
    font-size: 11px;
    font-weight: 300;
    color: #adadad;
    letter-spacing: 1px;
  }
}

/** todo css via https://codepen.io/shshaw/pen/WXMdwE 😻 */
.todo {
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  min-height: 40px;
  min-width: 40px;
  cursor: pointer;
  padding-right: 5px;
}
.todo__state {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.todo__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 280px;
  height: 100%;
  margin: auto;
  fill: none;
  stroke: #27FDC7;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  z-index: 1;
}

.todo__state:checked ~ .todo-list-title {
  text-decoration: line-through;
}

.todo__box {
  stroke-dasharray: 56.1053, 56.1053;
  stroke-dashoffset: 0;
  transition-delay: 0.16s;
}
.todo__check {
  stroke: #27FDC7;
  stroke-dasharray: 9.8995, 9.8995;
  stroke-dashoffset: 9.8995;
  transition-duration: 0.25s;
}

.todo__state:checked ~ .todo__icon .todo__box {
  stroke-dashoffset: 56.1053;
  transition-delay: 0s;
  stroke-dasharray: 56.1053, 56.1053;
  stroke-dashoffset: 0;
  stroke: red;
}

.todo__state:checked ~ .todo__icon .todo__check {
  stroke-dashoffset: 0;
  transition-delay: 0s;
}
